h1{
    font-size: 4.375rem;
    @include for-large-phone-only {
        font-size: 3.75rem;
    }     
}

h2{
    font-size: 2.625rem;
    color: $primary;
}

p{
    color: #4A4A4A;
    font-size: 1rem;
    line-height: 1.5rem;
    @media screen and (min-width: 1000px){
        font-size: 1.25rem;
        line-height: 2.25rem;
    }
}

strong{
    font-weight: 700;
}

em{
    font-style: italic;
}

a{
    color: #D5333F;
}
@mixin for-small-phone-only {
    @media screen and (max-width: 374px) {
        @content;
    }
}

@mixin for-large-phone-only {
    @media screen and (max-width: 599px) {
        @content;
    }
}

@mixin for-tablet-portrait-up {
    @media screen and (min-width: 600px) {
        @content;
    }
}

@mixin for-tablet-landscape-up {
    @media screen and (min-width: 900px) {
        @content;
    }
}

@mixin for-small-desktop-up {
    @media screen and (min-width: 1200px) {
        @content;
    }
}

@mixin for-medium-desktop-up {
    @media screen and (min-width: 1440px) {
        @content;
    }
}

@mixin for-large-desktop-up {
    @media screen and (min-width: 1800px) {
        @content;
    }
}
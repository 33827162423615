.footer{
    padding: 2.5rem 0 5rem 0;
    background-color: #7cc59a;
    color: white;
    font-size: 1.125rem;
    line-height: 1.875;
    .icon__phone--white{
        width: 20px;
        height: 20px
    }
    .icon__mail--white{
        width: 21px;
        height: 16px;
    }
    &__title{
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 1.5rem;
        color: white;
    }
    &__menu__item{
        color: white;
        text-decoration: none;
    }
    a{
        color: white;
        text-decoration: none;
        transition: color .3s ease-in-out;
        &:hover, &:focus{
            color: darken(#7CC59A, 25%);
            transition: color .3s ease-in-out;
        }
    }
    p{
        color: white;
        font-size: 1.125rem;
        line-height: 1.875;
    }
    .icon{
        margin-right: 0.5rem;
    }
    &__menu{
        margin-bottom: 1rem;
    }
    &__portfolio{
        margin-bottom: 1rem;
    }
    &__contact{
        margin-bottom: 1rem;
    }

}
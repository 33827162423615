// Font
$base-font-family: 'Open Sans', sans-serif;
$alt-font-family: 'droid-serif', serif;

// Colors
$gray-600: #1B1B1B;
$gray-400: #222;
$gray-100: #EAEAEA;

$primary: #7CC59A;

// Grid
$container-width: 1280px;

$bp-desktop-md: 1440px;
$bp-desktop-sm: 1280px;
$bp-tablet-lg: 1024px;
$bp-tablet-sm: 768px;
$bp-phone-lg: 600px;
$bp-phone-sm: 320px;
.btn{
    position: relative;
    padding: 1.5rem 1.5rem;
    font-size: 1.25rem;
    font-weight: 700;
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    color: white;
    text-decoration: none;
    overflow: hidden;
    transition: all .2s ease-in-out;
    @media screen and (min-width: 1000px){
        padding: 1.5rem 2.5rem;
        font-size: 1.5rem;
    }
    &:active{
        box-shadow: none;
        transform: translate(0, 5px);
        transition: all .2s ease-in-out;
    }
    .icon{
        width: 26px;
        height: 20px;
        margin-right: 1rem
    }
    &__primary{
        background-color: #d5333f;
        box-shadow: 0 5px 0 0 #9D030E;
        &:hover, &:focus{
            background-color: darken(#d5333f, 5%);
            transition: all .2s ease-in-out;
        }
    }
    &__secondary{
        background-color: #7cc59a;
        box-shadow: 0 5px 0 0 #569D73;
        &:hover, &:focus{
            background-color: darken(#7cc59a, 5%);
            transition: all .2s ease-in-out;
        }
    }
}
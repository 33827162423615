.topbar{
    padding: 1rem;
    box-shadow: 0 2px 14px 0 rgba(0,0,0,0.20);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    @media screen and (min-width: 1000px){
        padding: 1rem 3.5rem;
    }
    .icon{
        width: 1.5rem;
        height: 1.5rem;
        display: none;
        &:not(:last-of-type){
            margin-right: 2rem;
        }
        @media screen and (min-width: 1000px){
            display: block;
        }
    }
    &__logo{
        height: 6rem;
        width: 200px;
        background-image: url('assets_landingspage/logo-tekstschrijvert.png');
        background-size: contain;
        background-position: center left;
        background-repeat: no-repeat;
        flex-shrink: 0;
    }
    &__menu{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-end;
        flex-grow: 1;
    }
    .hamburger{
        margin-left: 2rem;
        display: block;
        @media screen and (min-width: 1000px){
            display: none;
        }
    }
}
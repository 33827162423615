.about{
    padding: 6rem 0 8rem 0;
    &__image{
        width: 22rem;
        height: 22rem;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: auto;
            height: 100%;
            max-width: none;
        }
    }
    &__content{
        padding-left: 2.5rem;
        p{
            margin-bottom: 2.25rem;
        }
        strong{
            font-weight: 600;
        }
    }
    &__title, h2{
        color: #D5333F;
        font-size: 1.75rem;
        font-weight: 600;
        line-height: 1.5;
        margin-bottom: 2rem;
        @media screen and (min-width: 800px){
            font-size: 1.75rem;
        }
    }
}
.contact{
    padding: 5rem 0 2.5rem 0;
    background-color: #7cc59a;
    text-align: center;
    &__action{
        padding: 1rem;
        background: #FFFFFF;
        box-shadow: 0 0 20px 0 rgba(0,0,0,0.16);
        border-radius: 5px;
        width: 100%;
        margin: 0 auto;
        @media screen and (min-width: 800px){
            width: calc((100%/12)*8);
            padding: 2.5rem;
        }
    }
    &__title{
        font-size: 1.25rem;
        line-height: 1.5;
        color: #4A4A4A;
        font-weight: 600;
        margin-bottom: 1rem;
    }
    .btn{
        margin-bottom: 1rem;
        padding: 1rem;
        font-size: 1rem;
    }
    @media screen and (min-width: 800px){
        .btn{
            font-size: 1.25rem;
        }
    }
}
.container{
    max-width: 1200px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0 auto;
}

.row{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;
}

.col{
    flex-basis: 0;
    // flex-grow: 1;
    max-width: 100%;
    padding: 0 1rem;
}

.flex-grow-1{
    flex-grow: 1;
}
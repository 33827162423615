.testimonial{
    padding: 2.5rem;
    background-color: white;
    text-align: center;
    border-radius: 5px;
    font-family: $alt-font-family;
    &__image{
        margin-bottom: 2rem;
        text-align: center;
        img{
            margin: 0 auto
        }
    }
    &__content{
        margin-bottom: 2rem;
    }
    &__user{
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}
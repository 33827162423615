.copyright{
    padding: 2rem 0;
    background-color: #4a4a4a;
    p{
        color: white;
        font-size: 0.75rem;
        text-align: center;
        line-height: 1.5;
        font-weight: 600;
    }
    a{
        color: white;
        text-decoration: none;
        transition: color .3s ease-in-out;
        &:hover{
            color: lighten(#4A4A4A, 40%);
            transition: color .3s ease-in-out;
        }
    }
}
.user{
    &__image{
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1.25rem;
        flex-shrink: 0;
        img{
            width: auto;
            height: 100%;
            max-width: none;
        }
    }
    &__info{
        text-align: left;
        font-family: $base-font-family;
    }
    &__name{
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 1.75;
        color: #7CC59A;
    }
    &__title{
        font-size: 1rem;
        line-height: 1.5;
        color: #B1B1B1;
    }
}
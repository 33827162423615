/*!
Theme Name: Tekstschrijvert landingspage
Author: De Merkenbrouwerij
Author URI: https://demerkenbrouwerij.nl
Description: Theme for Tekstschrijvert
Version: 1.0
*/
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,600i,700,700i');
@import url("https://use.typekit.net/kpg5qto.css");

@import "components/grid";

@import "settings";
@import "slick.scss";

@import "mixins/keyframes";
@import "mixins/breakpoints";
@import "components/reset";
@import "components/typography";
@import "components/body";
@import "components/wpcf7";
@import "components/buttons";
@import "components/icons";

// Page sections
@import "components/topbar";
@import "components/mainmenu";
@import "components/header";
@import "components/intro";
@import "components/services";
@import "components/service";
@import "components/about";
@import "components/testimonials";
@import "components/testimonial";
@import "components/user";
@import "components/contact";
@import "components/footer";
@import "components/copyright";
@import "components/slick";
@import "components/hamburger";
@import "components/mobile-menu";
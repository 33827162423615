.slick{
    &-slider{
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
    }
    &-arrow{
        width: 2.5rem;
        height: 2.5rem;
        appearance: none;
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        display: block;
        padding: 0;
        flex-shrink: 0;
    }
    &-prev{
        margin-right: 1rem;
    }
    &-next{
        margin-left: 1rem;
        transform: rotate(-180deg);
    }
    &-slide{
        padding: 0 1rem;
    }
    &-dots{
        display: flex;
        justify-content: center;
        li{
            padding: 0.25rem;
        }
        button{
            appearance: none;
            border: 0;
            background-color: white;
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            overflow: hidden;
            text-indent: -9999px;
            outline: none;
            cursor: pointer;
            transition: background .3s ease-in-out;
            &:hover, &:focus{
                background-color: lighten(#8EB1C4, 15%);
                transition: background .3s ease-in-out;
            }
        }
        .slick-active button{
            background-color: #8EB1C4;
        }
    }
}
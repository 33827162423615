.service{
    background-color: white;
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.05);
    border-radius: 6px;
    margin-bottom: 1rem;
    &__icon{
        border-radius: 6px 6px 0 0;
        background-color: #7CC59A;
        padding: 2rem 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon{
            height: 5.5rem;
            width: 5.5rem;
        }
        img{
            width: auto;
            height: 5.5rem;
        }
    }
    &__content{
        padding: 2rem 2.5rem;
        p{
            font-size: 1rem;
            line-height: 1.75rem;
        }
    }
    &__title, h3{
        color: #D5333F;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.25;
        margin-bottom: 1rem;
    }
}
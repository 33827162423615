.intro{
    padding: 6rem 0 8rem 0;
    &__title, h2{
        color: #d5333f;
        font-size: 2rem;
        font-weight: 300;
        line-height: 1.25;
        margin-bottom: 1.5rem;
        @media screen and (min-width: 800px){
            font-size: 3.25rem;
        }
    }
    &__image{
        width: 11rem;
        height: 11rem;
        border-radius: 50%;
        background: #b1b1b1;
        flex-shrink: 0;
        flex-grow: 0;
        overflow: hidden;
        display: flex;
        justify-content: center;
        img{
            width: auto;
            height: 100%;
            max-width: none;
        }
    }
    p{
        max-width: 36em;
    }
}
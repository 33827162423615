.wpcf7{
    .form-row{
        margin-bottom: 2rem;
        @include for-tablet-portrait-up {
            display: flex;
            flex-wrap: wrap;
        }            
    }
    .wpcf7-submit{
        -webkit-appearance: none;
        font-size: 2.25rem;
        line-height: 1;
        padding: 1.5rem 2.375rem 1rem 2.375rem;
        background-color: $primary;
        color: $gray-600;
        border: none;
        border: 1px solid $gray-600;
        box-shadow: 0 0 0 5px $primary;
        transition: box-shadow .2s ease-in-out;
        margin-bottom: 5px;
        margin-left: 5px;
        text-transform: uppercase;
        text-decoration: none;
        font-family: $alt-font-family;
        margin-bottom: 4.375rem;
        @include for-tablet-portrait-up {
            padding: 1.5rem 4.375rem 1rem 4.375rem;
        }

        
    }
    .form-row{
        margin-left: -1rem;
        margin-right: -1rem;
        > div{
            padding: 0 1rem;
        }
    }
    .form-textarea{
        margin-bottom: 2rem;
    }
    input[type="text"],
    input[type="email"]{
        background-color: transparent;
        border: 2px solid white;
        line-height: 2rem;
        margin-top: 0.75rem;
        padding: 0.5rem 1rem;
        color: white;
        font-family: $base-font-family;
        max-width: 300px;
        font-size: 1rem;
        box-sizing: border-box;
        margin-bottom: 1rem;
    }
    textarea{
        width: calc(100% - 2rem);
        background-color: transparent;
        border: 2px solid white;
        margin-top: 0.75rem;
        color: white;
        padding: 0.5rem 1rem;
        font-family: $base-font-family;
        font-size: 1rem;
    }
    .wpcf7-not-valid-tip{
        color: $primary;
        font-size: 0.75rem;
        line-height: 1.5;
    }
    .wpcf7-response-output{
        display: none!important;
    }
    label{
        font-size: 1.25rem;
    }
}

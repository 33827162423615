.mobile-menu{
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: #7CC59A;
    z-index: 300;
    width: 280px;
    padding: 3rem 0.5rem;
    word-break: break-all;
    box-shadow: -2px 0 14px 0 rgba(0, 0, 0, 0.2);
    visibility: hidden;
    opacity: 0;
    transform: translate(100%, 0);
    transition: all .2s ease-in-out;
    .mainmenu-open &{
        visibility: visible;
        opacity: 1;
        transform: translate(0,0);
        transition: all .2s ease-in-out;
    }
    .mobile-menu-title{
        color: white;
        padding: 0.5rem 1rem;
        font-weight: 700;
        text-transform: uppercase;
    }
    .mobile-menu-contact{
        padding: 0.5rem 1rem;
    }
    .sub-menu{
        display: none;
    }
    .mobile-menu-link{
        margin-bottom: 1rem;
        color: white;
        display: flex;
        align-items: center;
        a{
            color: white;
            text-decoration: none;
            &:hover, &:focus{
                color: darken(#7CC59A, 25%)
            }
        }
    }
    .page_item a,
    .menu-item a{
        color: white;
        padding: 0.5rem 1rem;
        display: block;
        text-decoration: none;
        line-height: 1.5;
        &:hover, &:focus{
            color: darken(#7CC59A, 25%)
        }
    }
    .icon__phone--white{
        width: 20px;
        height: 20px
    }
    .icon__mail--white{
        width: 21px;
        height: 16px;
    }
    .icon{
        margin-right: 0.5rem;
    }
}
@include keyframes(rotate-hamburger) {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(315deg); }
}

@include keyframes(rotate-hamburger-reverse) {
    0% { transform: rotate(45deg); }
    100% { transform: rotate(-360deg); }
}

.hamburger{
    width: 20px;
    height: 3px;
    display: inline-block;
    background-color: $primary;
    border-radius: 2px;
    border: none;
    &::before{
        content: "";
        width: 100%;
        height: 3px;
        background-color: $primary;
        transform: translate(0, -7px);
        display: block;
        border-radius: 2px;
    }
    &::after{
        content: "";
        width: 100%;
        height: 3px;
        background-color: $primary;
        transform: translate(0, 4px);
        display: block;
        border-radius: 2px;
    }
    .anim-start &{
        transform: rotate(45deg);
        animation-name: rotate-hamburger;
        animation-duration: .5s;
        animation-fill-mode: forwards;
    }
    .anim-end &{
        transform: rotate(0deg);
        animation-name: rotate-hamburger-reverse;
        animation-duration: .5s;
        animation-fill-mode: forwards;
    }
    .active &{
        transform: rotate(45deg);
        background-color: white;
        &::before{
            transform: translate(0px, 0px) rotate(90deg);
            background-color: white;
        }
        &::after{
            display: none;
        }
    }
}
.testimonials{
    padding: 6rem 0 8rem 0;
    background-color: #c8e1ee;
    &__title, h2{
        color: white;
        font-size: 1.75rem;
        font-weight: 600;
        line-height: 1.5;
        text-shadow: 0 2px 4px rgba(0,0,0,0.10);
        text-align: center;
        margin-bottom: 2rem;
    }
    &__container{
        width: 100%;
        margin: 0 auto;
        @media screen and (min-width: 800px){
            width: calc((100%/12)*9);
        }
    }
}
.mainmenu{
    display: none;
    @media screen and (min-width: 1000px){
        // display: block;
        display: flex;
        flex-wrap: wrap;
        margin-right: 1rem;
    }
    .page_item a,
    .menu-item a{
        display: block;
        padding: 1rem;
        color: #b1b1b1;
        font-weight: 600;
        text-decoration: none;
        transition: color .3s ease-in-out;
        white-space: nowrap;
        &:hover, &:focus{
            color: #7cc59a;
            transition: color .3s ease-in-out;
        }
    }
    .current_page_item a,
    .current-menu-item a{
        color: #7cc59a;
        transition: color .3s ease-in-out;
        &:hover, &:focus{
            color: darken(#7cc59a, 10%);
        }
    }
    .sub-menu{
        display: none;
    }
}

.mainmenu-trigger{
    -webkit-appearance: none;
    background: none;
    border: none;
    display: block;
    outline: none;
    cursor: pointer;
    .hamburger{
        margin-top: 2px;
        margin-right: 0.5rem;
    }
    &.active{
        z-index: 201;
    }
    .mainmenu-open &{
        z-index: 2000;
        position: fixed;
        top: 1rem;
        right: 1rem;
    }
}
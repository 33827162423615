.services{
    padding: 6rem 0 8rem 0;
    background-color: #d8eee1;
    &__title, h2{
        font-size: 1.25rem;
        font-weight: 600;
        color: #4A4A4A;
        margin-bottom: 2rem;
        text-align: center;
        @media screen and (min-width: 800px){
            font-size: 1.75rem;
        }
    }
    &__container{
        display: flex;
    }
}
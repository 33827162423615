.header{
    padding: 6rem 0 8rem 0;
    height: 36rem;
    position: relative;
    display: flex;
    align-items: center;
    &:before{
        content: "";
        background-image: url("assets_landingspage/slider-Amersfoort.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right center;
        width: 60%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
    }
    &:after{
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        background-image: linear-gradient(270deg, rgba(200,225,238,0.00) 0%, #7CC59A 60%);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left center, right center;
    }
    .container{
        position: relative;
        flex-grow: 1;
        z-index: 1;
    }
    &__title, h1{
        font-size: 2rem;
        font-weight: 300;
        color: white;
        line-height: 1.25;
        margin-bottom: 1.25rem;
        @media screen and (min-width: 800px){
            font-size: 3.25rem;
        }
    }
    &__subtitle, h2{
        font-size: 1.5rem;
        font-weight: 500;
        color: white;
        text-shadow: 0 2px 14px rgba(0,0,0,0.30);
        line-height: 1.5;
        margin-bottom: 2rem;
        @media screen and (min-width: 800px){
            font-size: 2rem;
        }
    }
}